<template>
  <microsoft-azure />
</template>

<script>
import MicrosoftAzure from "../../components/solutions/volume-licensing/MicrosoftAzure.vue";
export default {
  name: "microsoft-azure-page",
  components: { MicrosoftAzure },
};
</script>

<style></style>
